require('./bootstrap');

require('floatthead/dist/jquery.floatThead');

require('dropzone/dist/dropzone');

require('bootstrap-autocomplete/dist/latest/bootstrap-autocomplete.min');

// require('pdfobject/pdfobject.min');

import { Calendar } from '@fullcalendar/core';
window.Calendar = Calendar;


import interaction from '@fullcalendar/interaction';
window.interaction = interaction;

import dayGridPlugin from '@fullcalendar/daygrid';
window.dayGridPlugin = dayGridPlugin;

import timeGridPlugin from '@fullcalendar/timegrid';
window.timeGridPlugin = timeGridPlugin;

import listPlugin from '@fullcalendar/list';
window.listPlugin = listPlugin;


